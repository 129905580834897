body {
    margin: 0;
    font-family: Arial, sans-serif;
}

.toolbar {
    background-color: grey;
    padding: 10px;
    text-align: center;
}

.toolbar-header {
    margin: 0;
    color: white;
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}

.upload-form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 60px); /* Adjust for toolbar height */
    background: white;
}

.upload-form-card {
    background: rgba(0, 0, 255, 0.2);
    padding: 40px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    text-align: left; /* Align elements to the left */
    width: 400px;
}

.file-upload-label {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    font-size: 20px;
    color: #007bff;
    margin-bottom: 20px;
}

.file-upload-icon {
    font-size: 50px;
    margin-right: 10px;
}

.file-upload-text {
    font-size: 20px;
}

.file-names {
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkbox-container {
    margin-bottom: 20px;
}

.upload-button {
    background: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 20px;
    display: inline-block;
    position: relative;
}

.upload-button:hover {
    background: #0056b3;
}

.upload-button:disabled {
    background: #cccccc;
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-top: 4px solid #ffffff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    margin: 0 auto; /* Center the spinner */
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

input[type='checkbox'] {
    margin-right: 5px;
}

label[for='textOnly'] {
    margin-right: 20px;
}

.results a {
    display: block;
    margin-top: 20px;
    color: #007bff;
    text-decoration: none;
    text-align: left; /* Align the link to the left */
}

.results a:hover {
    color: #0056b3;
    text-decoration: underline;
}
